'use strict'
import 'babel-polyfill'
import index from './index.vue'
import store from './store/'
import router from './router'
import Vue from 'vue'
import layer from 'vue-layer'
import request, {VueAxios} from '../utils/requestWeb'
import header_ from './page/components/header_'
import footer_ from './page/components/footer_'
import myUploader from './page/components/myUploader'
import pagination from './page/components/pagination'
import createI18n from './i18n'
import './router/guard' // permission control
import ElementUI from 'element-ui'
import mui from '/assets/web/mui/js/mui.js'
import FastClick from 'fastclick'
import VueLazyload from "vue-lazyload"

if ('addEventListener' in document) {
    document.addEventListener('DOMContentLoaded', () => {
        FastClick.attach(document.body)
    }, false)
}
FastClick.prototype.focus = function (targetElement) {
    targetElement.focus()
}

const ver = navigator.userAgent.match(/cpu iphone os (.*?) like mac os/)

if (!ver) {
    FastClick.attach(document.body)
} else {
    if (parseInt(ver[1]) < 11) {
        FastClick.attach(document.body)
    }
}


Vue.use(ElementUI)
// Vue.use(VueLazyload);
Vue.use(VueLazyload, {
preLoad: 1,
error: '',
loading: '/assets/web/assets/load.gif',
attempt: 1
})


Vue.component('header_', header_)
Vue.component('footer_', footer_)
Vue.component('myUploader', myUploader)
Vue.component('pagination', pagination)

Vue.prototype.$layer     = layer(Vue)
Vue.config.productionTip = false
Vue.prototype.$request   = request
Vue.prototype.mui        = mui

Vue.config.ignoredElements = ['wx-open-launch-weapp']

store.commit('user/SET_TOKEN_USER', window.localStorage.getItem('Access-Token-User'))

let i18n = createI18n()

new Vue({
    router,
    store,
    i18n,
    // init localstorage, vuex
    render: h => h(index),
}).$mount('#app')

