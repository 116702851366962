var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", [
    _c(
      "div",
      {
        staticClass: "flex-between align-center pr w100",
        staticStyle: { "z-index": "100" },
      },
      [
        _c("div", { staticClass: "icon5box", on: { click: _vm.backClick } }),
        _vm._v(" "),
        _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("div", {
          staticClass: "icon6box",
          class: _vm.isMenu ? "icon6box on" : "icon6box",
          on: { click: _vm.menuClick },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isMenu,
            expression: "isMenu",
          },
        ],
        staticClass: "common_hmenu",
      },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _c("div", { staticClass: "icon" }, [
            _c("img", { attrs: { src: "/assets/web/assets/rmenu_icon1.png" } }),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("首页")]),
        ]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: "/user/sell" } }, [
          _c("div", { staticClass: "icon" }, [
            _c("img", { attrs: { src: "/assets/web/assets/rmenu_icon2.png" } }),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("我要卖")]),
        ]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: "/user/buyorder" } }, [
          _c("div", { staticClass: "icon" }, [
            _c("img", { attrs: { src: "/assets/web/assets/rmenu_icon3.png" } }),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("收货")]),
        ]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: "/user" } }, [
          _c("div", { staticClass: "icon" }, [
            _c("img", { attrs: { src: "/assets/web/assets/rmenu_icon5.png" } }),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("个人中心")]),
        ]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: "/user/history" } }, [
          _c("div", { staticClass: "icon" }, [
            _c("img", { attrs: { src: "/assets/web/assets/rmenu_icon6.png" } }),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("浏览历史")]),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "menumask",
      class: _vm.isMenumask ? "menumask show" : "menumask",
      on: { click: _vm.menumaskClick },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }