import enquireJs from 'enquire.js'

export function isDef(v) {
    return v !== undefined && v !== null
}

/**
 * Remove an item from an array.
 */
export function remove(arr, item) {
    if (arr.length) {
        const index = arr.indexOf(item)
        if (index > -1) {
            return arr.splice(index, 1)
        }
    }
}

export function isWeiXin() {
    let ua = window.navigator.userAgent.toLowerCase()

    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
    } else {
        return false
    }

}

export function isWeiXinQi() {
    let ua = window.navigator.userAgent.toLowerCase()

    if (ua.match(/wxwork/i) == 'wxwork') {
        return true
    } else {
        return false
    }

}


// 机型判断
export const getNativeModel = () => {
    const ua = navigator.userAgent.toLowerCase()
    if (/(Android|Linux|agent_android)/i.test(ua)) {
        return 'android'
    }

    if (/(iPhone|iPad|iPod|iOS|agent_ios)/i.test(ua)) {
        return 'ios'
    }
}

export function isRegExp(v) {
    return _toString.call(v) === '[object RegExp]'
}

export function enquireScreen(call) {
    const handler = {
        match:   function () {
            call && call(true)
        },
        unmatch: function () {
            call && call(false)
        },
    }
    enquireJs.register('only screen and (max-width: 767.99px)', handler)
}

const _toString = Object.prototype.toString
