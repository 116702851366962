<template>

  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive"/>
  </div>

</template>
<script type="text/babel">

export default {
  components: {},
  mounted() {
  },
  created() {
    // mozilla/5.0 (iphone; cpu iphone os 16_3_1 like mac os x) applewebkit/605.1.15 (khtml, like gecko)  mobile/15e148 wxwork/4.1.6 micromessenger/7.0.1 language/zh colorscheme/light
    // mozilla/5.0 (iphone; cpu iphone os 16_3_1 like mac os x) applewebkit/605.1.15 (khtml, like gecko) mobile/15e148 micromessenger/8.0.37(0x18002529) nettype/wifi language/zh_cn
  },

}
</script>
<style lang="less">
@import '/assets/element-ui/lib/theme-chalk/index.css';
@import '/assets/vant/index.css';

body {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
}
</style>
