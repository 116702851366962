<template>
  <header>
    <div class="flex-between align-center pr w100" style="z-index: 100;">
      <div class="icon5box" @click="backClick"></div>
      <!-- <img src="/assets/web/assets/icon5.png" style=" width: 0.25rem; height: 0.43rem;"
           > -->
      <span class="title">{{ title }}</span>
      <div class="icon6box" @click="menuClick" :class="isMenu ? 'icon6box on' : 'icon6box'"></div>
      <!-- <img src="/assets/web/assets/icon6.png" :class="isMenu ? 'icon6 on' : 'icon6'"
           @click="menuClick"> -->
    </div>
    <div class="common_hmenu" v-show="isMenu">
      <router-link to="/">
        <div class="icon">
          <img src="/assets/web/assets/rmenu_icon1.png">
        </div>
        <span>首页</span>
      </router-link>
      <router-link to="/user/sell">
        <div class="icon">
          <img src="/assets/web/assets/rmenu_icon2.png">
        </div>
        <span>我要卖</span>
      </router-link>
      <router-link to="/user/buyorder">
        <div class="icon">
          <img src="/assets/web/assets/rmenu_icon3.png">
        </div>
        <span>收货</span>
      </router-link>
      <router-link to="/user">
        <div class="icon">
          <img src="/assets/web/assets/rmenu_icon5.png">
        </div>
        <span>个人中心</span>
      </router-link>
      <router-link to="/user/history">
        <div class="icon">
          <img src="/assets/web/assets/rmenu_icon6.png">
        </div>
        <span>浏览历史</span>
      </router-link>
    </div>
    <div class="menumask" @click="menumaskClick" :class="isMenumask ? 'menumask show' : 'menumask'"></div>
  </header>
</template>

<script>
export default {
  name:  'commonTop',
  props: ['title'],
  data() {
    return {
      isMenu: false,
      isMenumask:false
    }
  },
  methods: {
    backClick() {
      this.$router.back(-1)
      this.isMenu=false
      this.isMenumask=false
    },
    menuClick() {
      this.isMenu = !this.isMenu
      this.isMenumask = !this.isMenumask
    },
    menumaskClick() {
      this.isMenu = !this.isMenu
      this.isMenumask = !this.isMenumask
      document.body.style.overflow = ''
    },
  },
}
</script>

<style lang="less" scoped>
  .icon5box{width: 0.69rem;height: 0.86rem;background: url(/assets/web/assets/icon5.png) no-repeat center center;background-size: 0.25rem auto;}
  .icon6box{width: 0.77rem;height: 0.86rem;background: url(/assets/web/assets/icon6.png) no-repeat center center;background-size: 0.33rem auto; transition: all 0.5s ease 0s;}
  .icon6box.on {    transform: rotate(90deg);  }

header {
  position: fixed;
  max-width: 640px;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 0;height: 0.86rem;
  background: url(/assets/web/assets/topbg.jpg) no-repeat center top;
  background-size: 7.5rem .87rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0;

  .title {
    font-size: 0.34rem;
    color: #fff;
    margin: 0;
    padding: 0
  }

  /* img {
    width: 0.86rem;
    transition: transform 0.4s;

    &.on {
      transform: rotate(90deg);
    }
  } */

  .icon6 {
    width: 0.33rem;
    height: 0.32rem;
    transition: all 0.5s ease 0s;
  }

  .icon6.on {
    transform: rotate(90deg);
  }

  .common_hmenu {
    position: absolute;
    top: 0.9rem;
    right: 0.2rem;
    width: 2.28rem;
    border-radius: 0.06rem; z-index: 50;
    padding: 0 .25rem 0 .2rem;
    box-sizing: border-box;
    background: url(/assets/web/assets/icon7.png) no-repeat center top;
    background-size: 2.28rem 5.46rem;

    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 0.85rem;
      border-bottom: 1px solid #7d7d7d;

      .icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 0.2rem;
        width: initial;

        img {
          width: 0.31rem;
        }
      }

      span {
        font-size: 0.28rem;
        color: #ffffff;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: -0.12rem;
      right: 0.08rem;
      border-left: 0.08rem solid transparent;
      border-right: 0.08rem solid transparent;
      border-bottom: 0.12rem solid #333333;
      display: none;
    }
  }
}
</style>
