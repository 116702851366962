import storage from 'store'
import {ACCESS_TOKEN_USER} from '../store/mutation-types'

const loginRoutePath = '/login'
import router from '../router'
import store from '../store/'
import Vue from 'vue'
import {getNativeModel} from '../../utils/util'

// 路由守卫
router.afterEach((to, from) => {
    // 需要调用的页面标识

    const isIOS = getNativeModel()
    let authUrl = window.location.href
    if (window.__wxjs_is_wkwebview || isIOS === 'ios') {
        if (!window.entryUrl) {
            window.entryUrl = authUrl
        }
    }
})


router.beforeEach((route, redirect, next) => {


    if (!Vue.prototype.link) {
        const url          = location.origin + (route.redirectedFrom || route.fullPath)
        Vue.prototype.link = url
    }

    // console.log(window.location.href)

    if (window.location.href.indexOf('//hemingz.com') >= 0) {
        // console.log('redirect')
        window.location.replace(window.location.href.replace('//hemingz.com','//www.hemingz.com'))
    }


    // if (window.location.href.indexOf('//xdz.hemingz.com') >= 0) {
    //     // console.log('redirect')
    //     window.location.replace(window.location.href.replace('xdz.hemingz.com','www.hemingz.com'))
    // }


    if (store.state.web.web_config.webname == undefined) {
        Vue.prototype.$request('/api/config_/', {data: {}}).then(results => {
            store.commit('web/setWebConfig', results.data)
        })
    }


    if (route.fullPath.startsWith('/user')) {
        if (storage.get(ACCESS_TOKEN_USER)) {

            let redirect_ = decodeURIComponent(redirect.query.redirect || route.path)

            redirect_ = redirect_.split('?')[0]

            if (route.path === redirect_) {
                next()
            } else {
                // 跳转到目的路由
                next({path: redirect_})
            }

        } else {

            if (route.path == loginRoutePath) {
                next()
            } else {
                next({path: loginRoutePath, query: {redirect: route.fullPath}})
            }
        }

    } else {

        let reload = true

        if (route.path == '/list' && redirect.path == '/detail') {
            reload = false
        }

        Vue.prototype.reload = reload

        next()
    }

})
