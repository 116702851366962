<template>
  <div>
    34
  </div>
</template>

<script>
export default {
  components: {},
  name:       'footer_',
};
</script>


