import axios from 'axios';
import store from '../web/store';
import storage from 'store';
import {VueAxios} from './axios';
import {ACCESS_TOKEN_USER} from '../web/store/mutation-types';

import router from '../web/router';


// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    timeout: 6000, // 请求超时时间
    method:  'post',
});

// 异常拦截处理器
const errorHandler = (error) => {
    return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use(config => {
    const token = storage.get(ACCESS_TOKEN_USER);
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers['Access-Token-User'] = token;
    }

    return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {

    const token = storage.get(ACCESS_TOKEN_USER);

    if (token && response.data.code == 99) {
        storage.remove(ACCESS_TOKEN_USER);
        location.href = '/login';
    }

    return response.data;
}, errorHandler);

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, request);
    },
};

export default request;

export {
    installer as VueAxios,
    request as axios,
};
