'use strict';
import Vue from 'vue';
import Vuex from 'vuex';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import user from './modules/user';
import web from './modules/web';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    web,
  },
  state:   {},
  mutations,
  actions,
  getters,
});
