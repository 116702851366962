import { ACCESS_TOKEN_USER } from '../mutation-types';

import storage from 'store';
import requestWeb from '../../../utils/requestWeb';

const user = {
  namespaced: true,
  state:      {
    user_token: '',
  },

  mutations: {
    SET_TOKEN_USER: (state, token) => {
      state.user_token = token;
    },
  },

  actions: {
    // 登出
    LogoutUser({ commit, state }) {
      return new Promise((resolve) => {

        requestWeb({
          url:    '/api/web/user/logout',
          method: 'post',
          data:   { token: state.user_token },
        }).then(res => {

          commit('SET_TOKEN_USER', '');

          storage.remove(ACCESS_TOKEN_USER);

          resolve();
        });


      });
    },


  },
};

export default user;
