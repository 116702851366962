import VueCookies from 'vue-cookies'

const web = {
    namespaced: true,
    state:      {
        web_config: {webname: undefined},
    },
    mutations:  {
        setWebConfig: (state, data) => {
            state.web_config = data
        },
        SET_I18N:     (state, data) => {
            VueCookies.set('locale', data)
        },

        SET_WEB_TITLE: (state, name) => {

            document.title = state.web_config.webname ? (name + '-' + state.web_config.webname) : name

        },

    },

    actions: {},
}

export default web
